import axios from "axios";
import {
  camelCase,
  concat,
  forEach,
  get,
  isNumber,
  isPlainObject,
  isString,
  snakeCase,
} from "lodash";
import client from "../../feathers";
import localStorage from "local-storage";

let accessKey = localStorage.get("accessKey");

export const routePrefix = {
  profile: "profile",
  asset: "asset",
  robot: "robots",
  parmRankings: "parm-rankings",
  enquiry: "enquiry",
  systemConfiguration: "system-configuration",
  user: "users",
  email: "email",
};

export const apiUrl = `${client.io.io.uri}api`;

const PAGESIZE = 30;

export const defaultPopulatedFieldName = (field) =>
  camelCase(`populated_${snakeCase(field)}`);

export async function _base_axios_get(url, query) {
  if (!url) {
    return new Promise((resolve, reject) => {
      reject({
        message: "Url Not Found!",
      });
    });
  }

  if (!isPlainObject(query)) {
    query = {};
  }

  return await axios
    .get(`${url}`, {
      params: {
        ...query,
      },
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then((res) => {
      return get(res, "data");
    })
    .catch((err) => {
      return new Promise((resolve, reject) => {
        reject({
          error: err,
          message: get(err, "response.data.message") || "Something went wrong!",
          errorType: get(err, "response.data.errorType") || "apiError",
        });
      });
    });
}

export async function _base_axios_post(url, query) {
  if (!url) {
    return new Promise((resolve, reject) => {
      reject({
        message: "Url Not Found!",
      });
    });
  }

  if (!isPlainObject(query)) {
    query = {};
  }

  return await axios
    .post(
      `${url}`,
      {
        ...query,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessKey,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return new Promise((resolve, reject) => {
        reject({
          error: err,
          message: get(err, "response.data.message") || "Something went wrong!",
          errorType: get(err, "response.data.errorType") || "apiError",
          errorCode: get(err, "response.data.code") || "",
        });
      });
    });
}

export async function _axios_base_get_list(
  url,
  limit = PAGESIZE,
  skip = 0,
  query = {}
) {
  if (!isString(url)) {
    return new Promise((resolve, reject) => {
      reject({
        type: "url_not_found",
        message: "Url not found",
      });
    });
  }

  let getAll = limit === "all";
  if (!isNumber(limit)) {
    limit = PAGESIZE;
  }

  if (getAll) {
    limit = 50;
  }

  if (!isNumber(skip)) {
    skip = 0;
  }

  if (!isPlainObject(query)) {
    query = {};
  }

  return await axios
    .get(url, {
      params: {
        ...query,
        limit: limit,
        skip: skip,
      },
      headers: {
        Authorization: "Bearer " + accessKey,
      },
    })
    .then(async (res) => {
      if (getAll) {
        let leftPage = Math.ceil(
          ((get(res, `data.total`) || 0) - limit) / limit
        );
        if (leftPage > 0) {
          let promises = [];
          for (let index = 1; index <= leftPage; index++) {
            promises.push(
              _axios_base_get_list(url, limit, index * limit, query)
            );
          }

          let responses = await Promise.all(promises);
          forEach(responses, (response) => {
            res.data.data = concat(
              res.data.data,
              get(response, `data.data`) || []
            );
          });
        }
      }
      return res.data;
    })
    .catch((err) => {
      return new Promise((resolve, reject) => {
        reject({
          errorType: get(err, "response.data.errorType") || "api_error",
          message: get(err, "response.data.message") || "API Error",
          err: err,
        });
      });
    });
}
