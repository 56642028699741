
import forced_liquidation from "./forced_liquidation.png";
import logoutIcon from "./logoutIcon.png";
import newLogo from "./newLogo.png";
import not_found from "./not_found.png";
import pre_stop from "./pre_stop.png";
import strategy_setting from "./strategy_setting.png";
import robotNav from "./robotNav.png";
import robotActiveNav from "./robotActiveNav.png";
import performanceNav from "./performanceNav.png";
import performanceActiveNav from "./performanceActiveNav.png";
import coins from "./coins.png";
import count_down_waiting from "./count_down_waiting.jpg";
import warning from "./warning.jpg";
import robotNotify from "./robotNotify.png";

export const count_down_waitingImg = count_down_waiting;
export const warningImg = warning;
export const forced_liquidationIcon = forced_liquidation;
export const coinsIcon = coins;
export const logoutIconImg = logoutIcon;
export const newLogoImg = newLogo;
export const not_foundIcon = not_found;
export const pre_stopIcon = pre_stop;
export const strategy_settingIcon = strategy_setting;
export const robotNavImg = robotNav;
export const robotActiveNavImg = robotActiveNav;
export const performanceNavImg = performanceNav;
export const performanceActiveNavImg = performanceActiveNav;
export const robotNotifyImg = robotNotify;
