import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";

// console.log(process.env.SERVER_ENDPOINT)
// const socket = io("http://localhost:3060/");
// const socket = io("http://localhost:3070/");
// const socket = io("https://ez3-api-e1.asiaezsight.com/");
const socket = io("https://demo-api.myits.co/");

//stage
// const socket = io("https://api.asiaezsight.com/");

//sit
// const socket = io('https://stg-api.asiaezsight.com/')
// const socket = io(process.env.SERVER_ENDPOINT);
// const socket = io('https://stg-api.asiaezsight.com/');
// const socket = io('https://sit-api.asiaezsight.com/');

const client = feathers();
client.configure(
  socketio(socket, {
    timeout: 50000,
    pingTimeout: 60000,
    transports: ["websocket"],
    forceNew: true,
    upgrade: false,
  })
);
client.configure(authentication());

export default client;
