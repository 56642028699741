export const routes = {
  home: {
    to: () => "/",
  },
  myits: {
    to: () => "https://indoits.com/",
    // to: () => "http://localhost:8001/",
  },
  login: {
    to: () => "/login",
  },
  highFrequency: {
    to: () => {
      return `/high_frequency`;
    },
  },
  highFrequencyDetails: {
    to: (id) => `/product/high_frequency/detail`,
  },
  transaction: {
    to: () => "/transaction",
  },
  liquidizedRobotList: {
    to: () => "/liquidized_robot_list",
  },
};
